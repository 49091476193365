// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-screens-404-index-js": () => import("./../../../src/screens/404/index.js" /* webpackChunkName: "component---src-screens-404-index-js" */),
  "component---src-screens-activate-1-index-js": () => import("./../../../src/screens/activate1/index.js" /* webpackChunkName: "component---src-screens-activate-1-index-js" */),
  "component---src-screens-activate-2-index-js": () => import("./../../../src/screens/activate2/index.js" /* webpackChunkName: "component---src-screens-activate-2-index-js" */),
  "component---src-screens-blog-index-js": () => import("./../../../src/screens/blog/index.js" /* webpackChunkName: "component---src-screens-blog-index-js" */),
  "component---src-screens-chinkee-index-js": () => import("./../../../src/screens/chinkee/index.js" /* webpackChunkName: "component---src-screens-chinkee-index-js" */),
  "component---src-screens-cms-page-js": () => import("./../../../src/screens/cms/page.js" /* webpackChunkName: "component---src-screens-cms-page-js" */),
  "component---src-screens-cms-post-js": () => import("./../../../src/screens/cms/post.js" /* webpackChunkName: "component---src-screens-cms-post-js" */),
  "component---src-screens-covid-19-index-js": () => import("./../../../src/screens/covid19/index.js" /* webpackChunkName: "component---src-screens-covid-19-index-js" */),
  "component---src-screens-developers-index-js": () => import("./../../../src/screens/developers/index.js" /* webpackChunkName: "component---src-screens-developers-index-js" */),
  "component---src-screens-free-index-js": () => import("./../../../src/screens/free/index.js" /* webpackChunkName: "component---src-screens-free-index-js" */),
  "component---src-screens-index-js": () => import("./../../../src/screens/index.js" /* webpackChunkName: "component---src-screens-index-js" */),
  "component---src-screens-pricing-index-js": () => import("./../../../src/screens/pricing/index.js" /* webpackChunkName: "component---src-screens-pricing-index-js" */),
  "component---src-screens-privacy-index-js": () => import("./../../../src/screens/privacy/index.js" /* webpackChunkName: "component---src-screens-privacy-index-js" */),
  "component---src-screens-restricted-businesses-index-js": () => import("./../../../src/screens/restricted-businesses/index.js" /* webpackChunkName: "component---src-screens-restricted-businesses-index-js" */),
  "component---src-screens-terms-index-js": () => import("./../../../src/screens/terms/index.js" /* webpackChunkName: "component---src-screens-terms-index-js" */),
  "component---src-screens-third-parties-index-js": () => import("./../../../src/screens/third-parties/index.js" /* webpackChunkName: "component---src-screens-third-parties-index-js" */)
}

